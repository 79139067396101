import ApiService from '@/services/core/api.service'

const DashboardService = {
  async get () {
    return ApiService.get('shared/get-all-moveout-by-dcp')
  },
  async getPerformingDealers (query = '') {
    return ApiService.get(`shared/performing-dealers?${query}`)
  },
  async getPerformingDcps (query = '') {
    return ApiService.get(`shared/performing-dcp?${query}`)
  },
  async getPerformingCategories (query = '') {
    return ApiService.get(`shared/performing-categories?${query}`)
  },
  async getAnnouncement (query = '') {
    return ApiService.get(`shared/active-announcement?${query}`)
  }
}

export default DashboardService
