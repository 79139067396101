function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/user/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const userRoutes = [
  {
    path: '/user/dashboard',
    component: view('Dashboard'),
    name: 'user.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/moveout',
    component: view('Moveout'),
    name: 'user.moveout',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Move out',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/booking',
    component: view('Booking'),
    name: 'user.booking',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Sample',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/inventory/beginning',
    component: view('InventoryBeginning'),
    name: 'user.inventory-beginning',
    meta: {
      breadcrumb: [
        {
          text: 'Inventory'
        },
        {
          text: 'Beginning',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/inventory/ending',
    component: view('InventoryEnding'),
    name: 'user.inventory-ending',
    meta: {
      breadcrumb: [
        {
          text: 'Inventory'
        },
        {
          text: 'Ending',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/invoice',
    component: view('Invoice'),
    name: 'user.invoice',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard'
        },
        {
          text: 'Invoice',
          active: true
        }
      ]
    }
  }
]

export default userRoutes
