import ApiService from '@/services/core/api.service'

const ProductService = {
  async get (query) {
    return ApiService.get(`marketing-officer/products?${query}`)
  },

  async post (payload) {
    return ApiService.post('marketing-officer/products', payload)
  },

  async put (payload) {
    return ApiService.put(`marketing-officer/products/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`marketing-officer/products/${payload.id}`)
  }
}

export default ProductService
