import ApiService from '@/services/core/api.service'

const AssignDealerService = {
  async get (query) {
    return ApiService.get(`marketing-officer/fetch-user-dealers?user_id=${query}`)
  },

  async post (payload) {
    return ApiService.post('marketing-officer/user-dealers', payload)
  },

  async put (payload) {
    // return ApiService.put(`marketing-officer/dealers/${payload.id}`, payload)
  },

  async delete (payload) {
    // return ApiService.delete(`marketing-officer/dealers/${payload.id}`)
  }
}

export default AssignDealerService
