import ApiService from '@/services/core/api.service'

const ReportService = {
  async get (query) {
    return ApiService.get(`marketing-officer/report?${query}`)
  },
  async getReportInvoice (query) {
    return ApiService.get(`marketing-officer/report-invoice?${query}`)
  }
}

export default ReportService
