import ApiService from '@/services/core/api.service'

const DistributionService = {
  async get (query) {
    return ApiService.get(`marketing-officer/distributions?${query}`)
  },

  async post (payload) {
    return ApiService.post('marketing-officer/distributions', payload)
  },

  async put (payload) {
    return ApiService.put(`marketing-officer/distributions/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`marketing-officer/distributions/${payload.id}`)
  }
}

export default DistributionService
