import ApiService from '@/services/core/api.service'

const StockService = {
  async getProductsListByDealer (query) {
    return ApiService.get(`shared/stocks?${query}`)
  },

  async post (payload) {
    return ApiService.post('shared/stocks', payload)
  },

  async put (payload) {
    return ApiService.put(`shared/edit-stocks?stock_id=${payload.stock_id}`, payload)
  }

//   async delete (payload) {
//     return ApiService.delete(`shared/users/${payload.id}`)
//   }
}

export default StockService
