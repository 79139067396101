import ApiService from '@/services/core/api.service'

const ProductCategoryService = {
  async get (query) {
    return ApiService.get(`marketing-officer/product-categories?${query}`)
  },

  async post (payload) {
    return ApiService.post('marketing-officer/product-categories', payload)
  },

  async put (payload) {
    return ApiService.put(`marketing-officer/product-categories/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`marketing-officer/product-categories/${payload.id}`)
  }
}

export default ProductCategoryService
