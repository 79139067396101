import ApiService from '@/services/core/api.service'

const header = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}
const AnnouncementService = {
  async get (query) {
    return ApiService.get(`marketing-officer/announcement?${query}`)
  },

  async addAnnouncement (payload) {
    return ApiService.post('marketing-officer/announcement', payload, header)
  },

  async removePhoto (payload) {
    return ApiService.delete(`marketing-officer/announcement/${payload.id}`, payload)
  },

  async uploadPhoto (payload) {
    return ApiService.post('marketing-officer/upload-photo', payload, header)
  },

  async deleteFile (id) {
    return ApiService.delete(`marketing-officer/delete-file/${id}`)
  },

  async updateAnnouncement (payload) {
    return ApiService.put(`marketing-officer/announcement/${payload.id}`, payload)
  }
}

export default AnnouncementService
