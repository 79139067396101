import sharedRoutes from './shared-routes'
import adminRoutes from './admin-routes'
import marketingOfficerRoutes from './marketing-officer-routes'
import userRoutes from './user-routes'
import AbmRoutes from './abm-routes'
const allRoutes = []

export default allRoutes.concat(
  sharedRoutes,
  adminRoutes,
  marketingOfficerRoutes,
  userRoutes,
  AbmRoutes
)
