import ApiService from '@/services/core/api.service'

const AssignAreaService = {
  async get (query) {
    return ApiService.get(`marketing-officer/fetch-user-area?${query}`)
  },

  async post (payload) {
    return ApiService.post('marketing-officer/user-area', payload)
  }
}

export default AssignAreaService
