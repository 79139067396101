import ApiService from '@/services/core/api.service'

const InvoiceService = {
  async getInvoices (query) {
    return ApiService.get(`shared/invoice?${query}`)
  },

  async post (payload) {
    return ApiService.post('shared/invoice', payload)
  },
  async getInvoiceProduct (payload) {
    return ApiService.get(`shared/invoice-product?invoice_id=${payload}`)
  },

  async put (payload) {
    return ApiService.put(`shared/invoice/${payload.invoice_id}`, payload)
  }

//   async delete (payload) {
//     return ApiService.delete(`shared/users/${payload.id}`)
//   }
}

export default InvoiceService
