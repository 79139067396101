import ApiService from '@/services/core/api.service'

const UserService = {
  async get (query) {
    return ApiService.get(`shared/users?${query}`)
  },

  async post (payload) {
    return ApiService.post('shared/users', payload)
  },

  async put (payload) {
    return ApiService.put(`shared/users/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`shared/users/${payload.id}`)
  },
  async getUserDcp (query) {
    return ApiService.get(`shared/users-dcp?${query}`)
  },
  async getDealerByDcp (query) {
    return ApiService.get(`shared/fetch-dealers-dcp?${query}`)
  },
  async getComment (query) {
    return ApiService.get(`shared/fetch-comments-dcp?${query}`)
  },
  async getIsUpdate () {
    return ApiService.get('shared/isupdate-users')
  }

}

export default UserService
