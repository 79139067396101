function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/marketing-officer/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const marketingOfficerRoutes = [
  {
    path: '/marketing-officer/dashboard',
    component: view('Dashboard'),
    name: 'marketing-officer.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/user-accounts',
    component: view('UserAccounts'),
    name: 'marketing-officer.user-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'User Management'
        },
        {
          text: 'User Accounts',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/maintenance/product-categories',
    component: view('ProductCategories'),
    name: 'marketing-officer.product-categories',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Product Categories',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/maintenance/products',
    component: view('Products'),
    name: 'marketing-officer.products',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Products',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/maintenance/announcement',
    component: view('Announcement'),
    name: 'marketing-officer.announcement',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Anouncement',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/maintenance/dealers',
    component: view('Dealers'),
    name: 'marketing-officer.dealers',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Dealers',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/maintenance/clusters',
    component: view('Clusters'),
    name: 'marketing-officer.clusters',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Clusters',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/maintenance/distributions',
    component: view('Distributions'),
    name: 'marketing-officer.distributions',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Distributions',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/maintenance/areas',
    component: view('Areas'),
    name: 'marketing-officer.areas',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Areas',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/report/sample',
    component: view('Report'),
    name: 'marketing-officer.report-sample',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Report',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/report/report-invoice',
    component: view('ReportInvoice'),
    name: 'marketing-officer.report-invoice',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Report Invoice',
          active: true
        }
      ]
    }
  },
  {
    path: '/marketing-officer/history/history',
    component: view('History'),
    name: 'marketing-officer.history',
    meta: {
      breadcrumb: [
        {
          text: 'History'
        },
        {
          text: 'History Update',
          active: true
        }
      ]
    }
  }
]

export default marketingOfficerRoutes
