import ApiService from '@/services/core/api.service'

const ListService = {
  async getProductCategories (query = '') {
    return ApiService.get(`shared/product-categories?${query}`)
  },
  async getProducts (query = '') {
    return ApiService.get(`shared/products?${query}`)
  },
  async getClusters () {
    return ApiService.get('shared/clusters')
  },
  async getDistributions (query = '') {
    return ApiService.get(`shared/distributions?${query}`)
  },
  async getAreas (query = '') {
    return ApiService.get(`shared/areas?${query}`)
  },
  async getDealers (query = '') {
    return ApiService.get(`shared/dealers?${query}`)
  },
  async getDealersByDcp (query = '') {
    return ApiService.get(`shared/dealers-by-dcp?${query}`)
  },
  async getMonth (query = '') {
    return ApiService.get(`shared/month?${query}`)
  },
  async getYear (query = '') {
    return ApiService.get(`shared/year?${query}`)
  }

}

export default ListService
