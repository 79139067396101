import ApiService from '@/services/core/api.service'

const MoveoutService = {
  async get (query) {
    return ApiService.get(`shared/moveout?${query}`)
  },

  async getBreakdownOfMoveOut (query) {
    return ApiService.get(`shared/move-out-breakdown?${query}`)
  }
}

export default MoveoutService
